import {
  faPen,
  faPlus,
  faSave,
  faSearch,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { StaticDialog } from "react-st-modal";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class Contracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      add: false,
      dados: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      loader: true,
      edit: {},
      trash: {},
    };
  }

  componentDidMount() {
    document.title = "Contratos";
    this.fetchData(this.state.currentPage);
  }

  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };

  fetchData(page) {
    fetch(`${apiUrl}/contracts?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 510) {
        localStorage.clear();
        window.location.href = "/";
        return;
      }

      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }
  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };
  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        {this.state.items.length > 0 && (
          <>
            <button
              className="btn btn-ligth"
              onClick={() => this.handlePageClick(currentPage - 1)}
              disabled={currentPage === 1}
              style={{
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
              }}
            >
              &lt;
            </button>

            {pages}

            <button
              onClick={() => this.handlePageClick(currentPage + 1)}
              className="btn btn-ligth"
              disabled={currentPage === totalPages}
              style={{
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
            >
              &gt;
            </button>
          </>
        )}
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong>Contratos</strong>
            <button
              class="btn btn-primary"
              onClick={() => {
                this.setState({ add: true });
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} /> Criar Contrato
            </button>
          </div>
          <div className="card-body">
            <div class="mb-3 d-flex justify-content-center"></div>
            {this.state.items.length > 0 ? (
              <>
                <table class="table table-striped table-bordered arvore-l">
                  <thead class="thead-dark">
                    <tr>
                      <th>Titulo</th>
                      <th>Data</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.title}</td>
                          <td>{item.created_at}</td>
                          <td className="btns">
                            <button
                              className="btn btn-primary"
                              onClick={(e) => {
                                console.log(item);
                                this.setState({ edit: item });
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                this.setState({ trash: item });
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>Não há resultados</>
            )}
          </div>
          <div
            className="card-footer"
            style={{ display: "flex", justifyContent: "center" }}
          ></div>
        </div>

        <StaticDialog
          isOpen={this.state.add}
          title="Criar Contrato"
          onAfterClose={(result) => {
            this.setState({ add: false });
          }}
        >
          <div className="content">
            <div className="mb-3">
              <label>Titulo</label>
              <input
                type="text"
                className="form-control mt-3"
                id="descricao"
                onChange={(e) => {
                  this.setState({
                    dados: { ...this.state.dados, title: e.target.value },
                  });
                }}
                value={this.state.dados.title || ""}
              />
            </div>
            <ReactQuill
              style={{ height: "100px", marginBottom: 50 }}
              onChange={(e) => {
                this.setState({ dados: { ...this.state.dados, content: e } });
              }}
            />
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.dados;

                fetch(`${apiUrl}/contract/create`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify(dados), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState((prevState) => ({
                          items: [
                            {
                              token: data.token,
                              title: dados.title,
                              data: data.created_at,
                            },
                            ...prevState.items,
                          ],
                          add: false,
                        }));

                        toast.success("Contrato criado com sucesso!");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="Editar Contrato"
          onAfterClose={(result) => {
            this.setState({ edit: {} });
          }}
        >
          <div className="content">
            <div className="mb-3">
              <label>Titulo</label>
              <input
                type="text"
                className="form-control mt-3"
                id="descricao"
                onChange={(e) => {
                  this.setState({
                    edit: { ...this.state.edit, title: e.target.value },
                  });
                }}
                value={this.state.edit.title || ""}
              />
            </div>
            <ReactQuill
              style={{ height: "100px", marginBottom: 50 }}
              value={this.state.edit.content || ""}
              onChange={(e) => {
                if (e == "<p><br></p>") {
                  return;
                }
                this.setState({ edit: { ...this.state.edit, content: e } });
              }}
            />
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.edit;

                fetch(`${apiUrl}/contract/${dados.token}/update`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({
                    title: dados.title,
                    content: dados.content,
                  }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState((prevState) => ({
                          items: prevState.items.map((item) =>
                            item.token === dados.token
                              ? {
                                  ...item,
                                  ...{
                                    title: dados.title,
                                    content: dados.content,
                                  },
                                }
                              : item
                          ),
                          edit: {},
                        }));

                        toast.success("Contrato editado com sucesso!");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Se você confirmar a exclusão, não será possível recuperar os dados
            posteriormente.
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var token = this.state.trash.token;
                fetch(`${apiUrl}/deletar-contrato`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({ token }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.ok) {
                      this.setState((prevState) => ({
                        items: prevState.items.filter(
                          (item) => item.token !== token
                        ),
                        trash: {},
                      }));

                      toast.success("Contrato excluido com sucesso!");
                    }
                  })
                  .catch((error) => {
                    toast.error("Erro ao enviar dados:", error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default Contracts;

import {
  faEye,
  faPen,
  faPrint,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";

class BudgetSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      budget: 0,
      expenses: [],
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
    };
  }

  componentDidMount() {
    document.title = "Orçamentos - Vendas";
    this.fetchData(this.state.currentPage);
  }

  formatDate(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    const secounds = String(data.getSeconds()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${secounds}`;
  }

  fetchData(page) {
    fetch(
      `${apiUrl}/list-budget-sales?page=${page}&limit=${this.state.itemsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  render() {
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };
    if (this.state.loader) {
      return (
        <div className="area-loader">
          <div className="loader"></div>
        </div>
      );
    }

    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong>Orçamentos - Vendas</strong>
          </div>
          <div className="card-body">
            <div className="mb-3">
              <Link to="/budget" className="btn btn-primary">
                Locações
              </Link>
              <button
                className="btn btn-primary"
                style={{ marginLeft: "10px" }}
                disabled
              >
                Vendas
              </button>
            </div>

            {this.state.items.length > 0 ? (
              <>
                <table class="table table-striped table-bordered arvore-l">
                  <thead class="thead-dark">
                    <tr>
                      <th>Id do cliente</th>
                      <th>Cliente</th>
                      <th>Vendedor</th>

                      <th>Produtos</th>
                      <th>Valor</th>

                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.client_id}</td>
                          <td>{item.client_name}</td>
                          <td>{item.seller_name}</td>

                          <td>
                            <button className="btn btn-primary">
                              <FontAwesomeIcon icon={faEye} /> visualizar
                            </button>
                          </td>
                          <td>
                            {item.location_value != null
                              ? item.location_value.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : "----"}
                          </td>

                          <td>
                            <div class="d-flex justify-content-center align-items-center">
                              <Link
                                className="btn btn-primary"
                                to={`/budget/sales/view/${item.token}`}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Link>

                              <Link
                                className="btn btn-primary"
                                to={`/budget/sales/print/${item.id}`}
                                style={{ marginLeft: "10px" }}
                              >
                                <FontAwesomeIcon icon={faPrint} />
                              </Link>
                              <button
                                className="btn btn-danger"
                                style={{ marginLeft: "10px" }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                          </td>
                        </tr>{" "}
                      </>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>Não há resultados</>
            )}
          </div>
          <div
            className="card-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {this.renderPagination()}
          </div>
        </div>
      </div>
    );
  }
}

export default BudgetSales;

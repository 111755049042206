import React, { Component } from "react";
import withLocation from "../../../comps/withLocation";
import { apiUrl } from "../../../comps/apiUrl";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPen,
  faPlus,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { StaticDialog } from "react-st-modal";

class ClientView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      data: {},
      selectedFile: null,
      TrashFileDoc: {},
      see: {},
    };
  }
  componentDidMount() {
    document.title = "Visualizar Cliente";
    fetch(`${apiUrl}/view-client/${this.props.params.token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 404) {
        this.setState({
          loader: false,
          error: true,
        });
      }

      response.json().then((data) => {
        this.setState({
          data,
          loader: false,
        });
      });
    });
  }
  handleFileChange = (e) => {
    const file = e.target.files[0]; // Obter o arquivo selecionado

    console.log("teste");

    if (file) {
      // Verificar se o arquivo é uma imagem
      const isImage = file.type.startsWith("image/");

      if (isImage) {
        const files = Array.from(e.target.files); // Converte para array
        this.setState({ selectedFile: files });
      } else {
        // Se não for uma imagem, exibir um erro
        toast.error("Por favor, selecione uma imagem.");
        this.setState({ selectedFile: null }); // Limpar o arquivo selecionado
      }
    }
  };

  FormatDataBr(data) {
    var date = new Date(data);
    return (
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds()
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.target.classList.add("load");

    // Verificar se o arquivo selecionado é uma imagem
    if (!this.state.selectedFile) {
      toast.error("Por favor, selecione uma imagem.");
      return;
    }

    const formData = new FormData();

    this.state.selectedFile.forEach((file, index) => {
      formData.append(`images`, file); // O backend deve suportar array de arquivos
    });

    fetch(`${apiUrl}/client-upload-document/${this.props.params.token}`, {
      method: "POST",

      body: formData,
    })
      .then((response) => {
        e.target.classList.remove("load");
        if (response.status === 200) {
          response.json().then((r) => {
            const newItems = r.files.map((item) => ({
              ...item,
              fileName: item.fileName,
            }));

            this.setState((prevState) => ({
              data: {
                ...prevState.data, // Mantém os outros dados dentro de `data`
                files: [...prevState.data.files, ...newItems], // Adiciona um novo item ao array
              },
            }));

            toast.success("Documento atualizado com sucesso.");
          });
        }
      })
      .catch((error) => {
        toast.error("Erro ao atualizar o documento.");
      });
  };
  TypeBlock(type) {
    if (type == 0) {
      return "Desbloqueado";
    } else if (type == 1) {
      return "Bloqueado Para Locação";
    } else if (type == 2) {
      return "Bloqueado Para Serviço";
    } else if (type == 3) {
      return "Bloqueio Total";
    } else {
      return "Desbloqueado";
    }
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong style={{ fontSize: 20 }}>Visualizar Cliente</strong>
          </div>
          <div className="card-body ">
            <div className="card mb-3">
              <div className="card-header d-flex align-items-center justify-content-between">
                <strong style={{ fontSize: 20 }}>Documentos</strong>
                <form className="d-flex" onSubmit={this.handleSubmit}>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={this.handleFileChange}
                    accept="image/*" // Restringe a seleção para arquivos de imagem
                  />
                  <button
                    type="submit"
                    style={{ marginLeft: 15 }}
                    className="btn btn-primary"
                  >
                    <FontAwesomeIcon icon={faUpload} />
                  </button>
                </form>
              </div>
              <div className="card-body">
                {this.state.data.files.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {this.state.data.files.map((item) => (
                        <>
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block", // Para aj
                            }}
                          >
                            <img
                              src={`${apiUrl}/img/${item.fileName}`}
                              style={{
                                width: 100,
                                height: 100,
                                marginRight: 10,
                                border: "1px solid black",
                                cursor: "pointer",
                              }}
                              className="img-fluid"
                              alt="..."
                            />
                            <div
                              style={{
                                position: "absolute",
                                display: "flex",
                                justifyContent: "space-between",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                padding: "5px 10px",
                              }}
                            >
                              <button
                                className="btn btn-primary"
                                style={{
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                                onClick={(e) => {
                                  this.setState({ see: item });
                                }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </button>

                              <button
                                className="btn btn-danger"
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                                onClick={() =>
                                  this.setState({
                                    TrashFileDoc: item,
                                  })
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>Sem documento!</>
                )}
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header d-flex align-items-center justify-content-between">
                <strong style={{ fontSize: 20 }}>Histórico do Cliente</strong>
              </div>
              <div className="card-body">
                <table className="table table-striped table-bordered arvore-l">
                  <thead>
                    <th>Status</th>
                    <th>Obeservação</th>
                    <th>Data</th>
                  </thead>
                  <tbody>
                    {this.state.data.history.map((item) => (
                      <tr>
                        <td>{this.TypeBlock(item.status)}</td>
                        <td>{item.obs}</td>
                        <td>{this.FormatDataBr(item.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {this.state.data.history.length > 0 ? (
                  <></>
                ) : (
                  <> Sem historico</>
                )}
              </div>
            </div>

            {this.state.data.tipo_pessoa == "F" ? (
              <>
                <div className="card">
                  <div className="card-body">
                    <div>
                      <p>
                        <strong>Status:</strong>{" "}
                        {this.TypeBlock(this.state.data.status)}
                      </p>
                      <div className="mb-2">
                        <span>Informações Pessoais:</span>
                      </div>
                      <p>
                        <strong>Nome:</strong> {this.state.data.nome}
                      </p>
                      <p>
                        <strong>CPF:</strong> {this.state.data.cnpj_cpf}
                      </p>
                      <p>
                        <strong>RG:</strong>{" "}
                        {this.state.data.rg != "" ? (
                          this.state.data.rg
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <div className="mb-2">
                        <span>Contato:</span>
                      </div>
                      <p>
                        <strong>Telefone fixo:</strong>{" "}
                        {this.state.data.telefone != "" ? (
                          this.state.data.telefone
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Celular: </strong>
                        {this.state.data.celular != "" ? (
                          this.state.data.celular
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <p>
                        <strong>Email:</strong>
                        {this.state.data.email != "" ? (
                          this.state.data.email
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <div className="mb-2">
                        <span>Endereço:</span>
                      </div>
                      <p>
                        <strong>Endereço: </strong>
                        {this.state.data.endereco != "" ? (
                          this.state.data.endereco
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <p>
                        <strong>Número: </strong>
                        {this.state.data.numero != "" ? (
                          this.state.data.numero
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Bairro: </strong>
                        {this.state.data.bairro != "" ? (
                          this.state.data.bairro
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Cidade: </strong>
                        {this.state.data.cidade != "" ? (
                          this.state.data.cidade
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Estado: </strong>
                        {this.state.data.uf != "" ? (
                          this.state.data.uf
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Cep: </strong>
                        {this.state.data.cep != "" ? (
                          this.state.data.cep
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="card">
                  <div className="card-body">
                    <div>
                      <div className="mb-2">
                        <span>Informações Pessoais:</span>
                      </div>
                      <p>
                        <strong>Nome:</strong> {this.state.data.nome}
                      </p>
                      <p>
                        <strong>CNPJ:</strong> {this.state.data.cnpj_cpf}
                      </p>
                      <p>
                        <strong>Inscrição estadual:</strong>{" "}
                        {this.state.data.inscricao_estadual != "" ? (
                          this.state.data.inscricao_estadual
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <div className="mb-2">
                        <span>Contato:</span>
                      </div>
                      <p>
                        <strong>Telefone fixo:</strong>{" "}
                        {this.state.data.telefone != "" ? (
                          this.state.data.telefone
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Celular: </strong>
                        {this.state.data.celular != "" ? (
                          this.state.data.celular
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <p>
                        <strong>Email:</strong>
                        {this.state.data.email != "" ? (
                          this.state.data.email
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <div className="mb-2">
                        <span>Endereço:</span>
                      </div>
                      <p>
                        <strong>Endereço: </strong>
                        {this.state.data.endereco != "" ? (
                          this.state.data.endereco
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <p>
                        <strong>Número: </strong>
                        {this.state.data.numero != "" ? (
                          this.state.data.numero
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Bairro: </strong>
                        {this.state.data.bairro != "" ? (
                          this.state.data.bairro
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Cidade: </strong>
                        {this.state.data.cidade != "" ? (
                          this.state.data.cidade
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Estado: </strong>
                        {this.state.data.uf != "" ? (
                          this.state.data.uf
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Cep: </strong>
                        {this.state.data.cep != "" ? (
                          this.state.data.cep
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.see).length > 0}
          title="Visualizar Documento"
          onAfterClose={(result) => {
            this.setState({ see: {} });
          }}
        >
          <img src={`${apiUrl}/img/${this.state.see.fileName}`} alt="" />
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.TrashFileDoc).length > 0}
          title="Excluir Documento ?"
          onAfterClose={(result) => {
            this.setState({ TrashFileDoc: {} });
          }}
        >
          <div style={{ textAlign: "center", margin: 10 }}>
            <p>Tem certeza que deseja excluir o documento?</p>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(`${apiUrl}/delete-document`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    id: this.state.TrashFileDoc.id,
                    token: this.props.params.token,
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    e.target.classList.remove("load");
                    this.setState({ TrashFileDoc: {} });
                    if (data.error) {
                      toast.error(data.error);
                    } else {
                      this.setState({
                        data: {
                          ...this.state.data,
                          files: this.state.data.files.filter(
                            (doc) => doc.id !== this.state.TrashFileDoc.id
                          ),
                        },
                      });
                      toast.success("Documento excluido com sucesso");
                    }
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default withLocation(ClientView);

import {
  faBuilding,
  faClipboard,
  faClock,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileInvoice,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faPen,
  faPhone,
  faPlus,
  faSave,
  faStore,
  faTrash,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";
import { StaticDialog } from "react-st-modal";
import { TextField, Button } from "@mui/material";
import InputMask from "react-input-mask";
class Lease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      showModal: false,
      data: [],
      dados: [],
      dataClient: [],
      addEquipament: false,
      busca: "",
      rBusca: [],

      BuscaM: "",
      rBuscaM: [],
      selectedFiles: [],
      products: [],
      newProduct: false,
      equipaments: [],
      cpfCnpj: "",
      previews: [],
      tpcad: "cpf",
    };
  }
  handleCpfCnpjChange = (e) => {
    console.log(e.target);
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;

    if (e.target.value.length <= 14) {
      this.setState({
        cpfCnpj: cpf(e.target.value),
      });
    } else {
      this.setState({
        cpfCnpj: cnpj(e.target.value),
      });
    }
  };
  DateNow() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Mês começa em 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    // Formata no padrão YYYY-MM-DDTHH:mm
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  componentDidMount() {
    document.title = "Locação";
    this.setState({
      dados: {
        start_day: this.DateNow(),
      },
    });
    fetch(`${apiUrl}/leaser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status == 510) {
          window.localStorage.clear();
          window.location.href = "/";
        }
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({
              data,
              loader: false,
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as informações");
      });

    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    // Remove o listener ao desmontar o componente
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.key === "F1") {
      if (
        this.state.addEquipament == true ||
        this.state.dados.cliente != undefined
      ) {
        return;
      }
      event.preventDefault(); // Evita o comportamento padrão do navegador

      this.setState({ showModal: true });
    }

    if (event.key === "F2") {
      if (this.state.dados.cliente == undefined) {
        return;
      }
      this.setState({
        addEquipament: true,
      });
    }

    if (event.key === "F4") {
    }
  };

  handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    let validFiles = [];
    let previews = [];

    files.forEach((file) => {
      if (file.type.startsWith("image/")) {
        validFiles.push(file);
        previews.push(URL.createObjectURL(file));
      } else {
        toast.error("Apenas imagens são permitidas!");
      }
    });

    // Concatenar as novas imagens com as existentes
    this.setState((prevState) => ({
      selectedFiles: [...prevState.selectedFiles, ...validFiles],
      previews: [...prevState.previews, ...previews],
    }));
  };

  handleRemoveImage = (index) => {
    const { selectedFiles, previews } = this.state;

    // Remover o arquivo e a pré-visualização do estado
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    const updatedPreviews = previews.filter((_, i) => i !== index);

    this.setState({ selectedFiles: updatedFiles, previews: updatedPreviews });
  };
  TypeBlock(type) {
    if (type == 0) {
      return <span>Desbloqueado</span>;
    } else if (type == 1) {
      return <span style={{ color: "red" }}>Bloqueado Para Locação</span>;
    } else if (type == 2) {
      return <span style={{ color: "red" }}>Bloqueado Para Serviço</span>;
    } else if (type == 3) {
      return <span>Bloqueio Total</span>;
    } else {
      return <span>Desbloqueado</span>;
    }
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleStartDateChange = (event) => {
    this.setState({ startDate: event.target.value }, this.calculateDifference);
  };

  handleEndDateChange = (event) => {
    this.setState({ endDate: event.target.value }, this.calculateDifference);
  };

  calculateDifference = () => {
    const { startDate, endDate } = this.state;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (
        start instanceof Date &&
        end instanceof Date &&
        !isNaN(start) &&
        !isNaN(end)
      ) {
        const differenceInTime = end.getTime() - start.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        this.setState({
          dayDifference: Math.max(0, Math.floor(differenceInDays)),
        });
      } else {
        this.setState({ dayDifference: null });
      }
    } else {
      this.setState({ dayDifference: null });
    }
  };
  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handlenCChange = (event) => {
    const { name, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dataClient: {
        ...prevState.dataClient, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  EndDay() {
    var billing = {
      1: 1,
      2: 7,
      3: 15,
      4: 30,
      5: 365,
    };

    const date = new Date(this.state.dados.start_day);
    if (!isNaN(date)) {
      var dias =
        this.state.dados.period !== undefined
          ? this.state.dados.period * billing[this.state.dados.faturamento]
          : 0;
      date.setDate(date.getDate() + dias);

      // Construção manual do formato 'YYYY-MM-DD HH:mm'
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    } else {
      return 0;
    }
  }
  calculateTotal = () => {
    var billing = {
      1: 1,
      2: 7,
      3: 15,
      4: 30,
      5: 365,
    };
    if (this.state.data.type == "sale" || this.state.data.type == "o-v") {
      return this.state.products.reduce((total, item) => {
        const selectedPrice = item.value; // Preço selecionado com base no faturamento
        return total + selectedPrice * item.quantity; // Soma o preço de cada item com a quantidade
      }, 0); // Multiplica o total final pelos dias
    } else {
      return (
        this.state.products.reduce((total, item) => {
          const selectedPrice = item.price[this.state.dados.faturamento]; // Preço selecionado com base no faturamento
          return total + selectedPrice * item.quantity; // Soma o preço de cada item com a quantidade
        }, 0) * this.state.dados.period
      ); // Multiplica o total final pelos dias
    }
  };

  render() {
    if (this.state.loader) {
      return (
        <div className="area-loader">
          <div className="loader"></div>
        </div>
      );
    }
    var { dayDifference } = this.state;

    const total = this.calculateTotal(); // Total calculado
    var EndDay = this.EndDay();
    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong>Locação de Equipamentos</strong>
            <Link className="btn btn-success" to="/lease-history">
              <FontAwesomeIcon icon={faClock} /> Histórico de Locações
            </Link>
          </div>
          <div className="card-body">
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="nome-cliente" class="form-label">
                  Tipo
                </label>
                <select
                  class="form-control"
                  id="vendedor"
                  onChange={(e) => {
                    if (e.target.value == "") {
                      return;
                    }
                    this.setState({
                      data: {
                        ...this.state.data, // Mantém os outros campos do objeto `data`
                        ["type"]: e.target.value, // Atualiza apenas o campo correspondente
                      },
                    });

                    this.setState({
                      dados: {
                        ...this.state.dados, // Mantém os outros campos do objeto `data`
                        ["cliente"]: undefined, // Atualiza apenas o campo correspondente
                      },
                    });
                  }}
                  value={this.state.data.type}
                >
                  <option value="">Selecione</option>
                  <option value="location">Locação</option>
                  <option value="sale">Venda</option>
                  <option value="o-l">Orçamento Locação</option>
                  <option value="o-v">Orçamento Venda</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="nome-cliente" class="form-label">
                  Vendedor
                </label>

                <input
                  type="text"
                  class="form-control"
                  value={this.state.data.sellers.name}
                  disabled
                />
              </div>
              <div class="col-md-4">
                <label for="loja" class="form-label">
                  Loja
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.data.store.name}
                  disabled
                />
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-header d-flex align-items-center justify-content-between">
                <strong>Informações do Cliente</strong>

                <div></div>
              </div>
              <div className="card-body">
                {this.state.dados.cliente != undefined ? (
                  <>
                    <label>Cliente</label>
                    <table class="table table-striped table-bordered arvore-l mt-2">
                      <thead class="thead-dark">
                        <tr>
                          <th>Nome</th>
                          <th>Documento</th>
                          <th>Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.dados.cliente.nome}</td>
                          <td>{this.state.dados.cliente.cnpj_cpf}</td>
                          <td>
                            <button
                              class="btn btn-danger"
                              onClick={(e) => {
                                this.setState((prevState) => {
                                  const { cliente, ...rest } = prevState.dados; // Desestrutura e exclui "cliente"
                                  return {
                                    dados: rest, // Atualiza "dados" com o restante das propriedades
                                  };
                                });
                              }}
                            >
                              Excluir
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      disabled={
                        this.state.dados.cliente == undefined ? false : true
                      }
                      class="btn btn-primary mb-2"
                      onClick={() => this.setState({ showModal: true })}
                    >
                      <FontAwesomeIcon icon={faUserPlus} /> Buscar cliente (F1)
                    </button>
                    <button
                      type="button"
                      disabled={
                        this.state.dados.cliente == undefined ? false : true
                      }
                      class="btn btn-primary mb-2"
                      style={{ marginLeft: "10px" }}
                      onClick={() => this.setState({ newC: true })}
                    >
                      <FontAwesomeIcon icon={faUserPlus} /> Novo cliente
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="card mt-2 mb-2">
              <div className="card-header d-flex justify-content-between">
                <strong>Equipamentos</strong>
              </div>
              <div className="card-body">
                {this.state.products.length > 0 ? (
                  <>
                    <table class="table table-striped table-bordered arvore-l">
                      <thead class="thead-dark">
                        <tr>
                          <th>Código de barra</th>
                          <th scope="col">Nome</th>
                          <th>Estoque </th>

                          <th>Valor unitário</th>

                          <th>Quantidade</th>
                          {this.state.dados.faturamento != undefined && (
                            <>
                              <th>Total</th>
                            </>
                          )}
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.products.map((product) => (
                          <tr>
                            <td>{product.codigo_barra}</td>
                            <td>{product.name}</td>
                            <td>
                              {this.state.data.store.id == 1
                                ? product.stockone
                                : product.stocktwo}
                            </td>
                            {this.state.data.type == "location" ||
                            this.state.data.type == "o-l" ? (
                              <>
                                <td>
                                  {this.state.dados.faturamento != undefined
                                    ? product.price[
                                        this.state.dados.faturamento
                                      ].toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    : "----"}
                                </td>
                              </>
                            ) : (
                              <td>
                                {product.value.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </td>
                            )}
                            <td style={{ width: 200 }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    var id = product.id;
                                    this.setState((prevState) => {
                                      const novosItens = prevState.products.map(
                                        (item) => {
                                          if (item.id === id) {
                                            // Verifica se a quantidade é maior que 1 antes de diminuir
                                            const novaQuantidade =
                                              item.quantity > 1
                                                ? item.quantity - 1
                                                : 1;
                                            return {
                                              ...item,
                                              quantity: novaQuantidade, // Atualiza a quantidade
                                            };
                                          }
                                          return item;
                                        }
                                      );

                                      return { products: novosItens };
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <input
                                  type="text"
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 50,
                                    textAlign: "center",
                                  }}
                                  value={product.quantity}
                                  className="form-control"
                                  disabled
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    var id = product.id;
                                    var max =
                                      this.state.data.store.id == 1
                                        ? product.stockone
                                        : product.stocktwo;

                                    this.setState((prevState) => {
                                      const novosItens = prevState.products.map(
                                        (item) => {
                                          if (item.id === id) {
                                            if (item.quantity >= max) {
                                              toast.warn(
                                                "Quantidade máxima atingida"
                                              );
                                              return item; // Retorna o item sem alteração
                                            }

                                            return {
                                              ...item,
                                              quantity: item.quantity + 1, // Incrementa a quantidade
                                            };
                                          }
                                          return item;
                                        }
                                      );

                                      return { products: novosItens };
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </button>
                              </div>
                            </td>
                            {this.state.dados.faturamento != undefined && (
                              <>
                                <td>
                                  {(
                                    product.quantity *
                                    product.price[this.state.dados.faturamento]
                                  ).toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </td>
                              </>
                            )}
                            <td>
                              <button
                                class="btn btn-danger"
                                onClick={(e) => {
                                  var id = product.id;
                                  this.setState((prevState) => {
                                    // Filtra o array de itens, removendo o item pelo id
                                    const novosItens =
                                      prevState.products.filter(
                                        (item) => item.id !== id
                                      );

                                    return { products: novosItens }; // Atualiza o estado com o novo array de itens
                                  });
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          this.setState({
                            addEquipament: true,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Adicionar equipamento
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        this.setState({
                          addEquipament: true,
                        });
                      }}
                      disabled={
                        this.state.data.type == undefined ||
                        this.state.dados.cliente == undefined
                          ? true
                          : false
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> Adicionar equipamento
                    </button>
                  </div>
                )}
              </div>
            </div>
            {(this.state.data.type == "o-l" ||
              this.state.data.type == "location") && (
              <>
                {" "}
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="data-inicial" class="form-label">
                      Faturamento
                    </label>
                    <select
                      className="form-control"
                      id="faturamento"
                      name="faturamento"
                      onChange={(e) => {
                        if (e.target.value == "0") {
                          e.preventDefault();
                          return false;
                        }

                        this.handleChange(e);
                      }}
                      value={this.state.dados.faturamento}
                      disabled={
                        this.state.dados.cliente == undefined ? true : false
                      }
                    >
                      <option value="0">
                        Selecione o período do faturamento
                      </option>
                      <option value="1">Diária</option>
                      <option value="2">Semanal</option>
                      <option value="3">Quinzenal</option>
                      <option value="4">Mensal</option>
                      <option value="5">Anual</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <label for="data-inicial" class="form-label">
                      Quantidade de período
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.dados.period}
                      onChange={(e) => {
                        var value = e.target.value.replace(/\D/g, "");

                        this.setState((prevState) => ({
                          dados: {
                            ...prevState.dados, // Mantém os outros campos do objeto `dados`
                            ["period"]: value, // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      disabled={
                        this.state.dados.cliente == undefined ? true : false
                      }
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="data-inicial" class="form-label">
                      Data Inicial - {this.state.dados.start_day}
                    </label>
                    <input
                      type="datetime-local"
                      id="data-inicial"
                      class="form-control"
                      required=""
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          dados: {
                            ...prevState.dados, // Mantém os outros campos do objeto `dados`
                            ["start_day"]: e.target.value, // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      value={this.state.dados.start_day}
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="data-final" class="form-label">
                      Data Final - {this.state.dados.end_day}
                    </label>
                    <input
                      type="datetime-local"
                      id="data-final"
                      class="form-control"
                      value={EndDay}
                      required=""
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            )}

            <div class="row mb-3 d-flex align-items-center">
              {(this.state.data.type == "o-l" ||
                this.state.data.type == "location") && (
                <>
                  {" "}
                  <div class="col-md-2">
                    <label for="dias-locacao" class="form-label">
                      Valor da locação
                    </label>
                    <input
                      value={
                        isNaN(total)
                          ? "R$ 0,00"
                          : total.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                      }
                      id="dias-locacao"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="col-md-2">
                    <label for="dias-locacao" class="form-label">
                      Multa por Atraso
                    </label>
                    <input
                      value={this.state.dados.multa}
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          dados: {
                            ...prevState.dados, // Mantém os outros campos do objeto `dados`
                            ["multa"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      class="form-control"
                    />
                  </div>
                </>
              )}

              {(this.state.data.type == "sale" ||
                this.state.data.type == "o-v") && (
                <>
                  <div class="col-md-2">
                    <label for="dias-locacao" class="form-label">
                      Valor total
                    </label>
                    <input
                      value={
                        isNaN(total)
                          ? "R$ 0,00"
                          : total.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                      }
                      id="dias-locacao"
                      class="form-control"
                      disabled
                    />
                  </div>
                </>
              )}

              {this.state.data.type != undefined && (
                <>
                  <div class="col-md-2">
                    <label for="dias-locacao" class="form-label">
                      Frete
                    </label>
                    <input
                      value={this.state.dados.frete}
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          dados: {
                            ...prevState.dados, // Mantém os outros campos do objeto `dados`
                            ["frete"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="dias-locacao" class="form-label">
                      Observações
                    </label>
                    <textarea
                      class="form-control"
                      value={this.state.dados.obs}
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          dados: {
                            ...prevState.dados, // Mantém os outros campos do objeto `dados`
                            ["obs"]: e.target.value, // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      placeholder="Digite:"
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="dias-locacao" class="form-label">
                      Forma de pagamento
                    </label>

                    <select
                      name="tpay"
                      class="form-control"
                      onChange={(e) => this.handleChange(e)}
                      required=""
                    >
                      <option value="" disabled="" selected="">
                        Selecione
                      </option>
                      {this.state.data.type == "location" ? (
                        <>
                          <option value="pne">Pagamento Na Entrega</option>
                          <option value="pnd">Pagamento Na Devolução</option>
                          <option value="boletop">Boleto</option>
                        </>
                      ) : (
                        <>
                          <option value="boleto">Boleto</option>
                        </>
                      )}

                      <option value="cartaoc">Cartão de Crédito</option>
                      <option value="cartaod">Cartão de Débito</option>
                      <option value="dinheiro">Dinheiro</option>
                      <option value="pix">PIX</option>
                    </select>
                  </div>
                </>
              )}
            </div>
            {dayDifference != null && (
              <>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="dias-locacao" class="form-label">
                      Dias de Locação
                    </label>
                    <input
                      value={dayDifference != null ? dayDifference : ""}
                      id="dias-locacao"
                      class="form-control"
                      disabled="true"
                    />
                  </div>

                  <div class="col-md-3">
                    <label for="valor-total" class="form-label">
                      Valor Total
                    </label>
                    <input
                      type="text"
                      id="valor-total"
                      value={(
                        dayDifference *
                        this.state.dados.quantidade *
                        this.state.dados.price
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      class="form-control"
                      disabled="true"
                    />
                  </div>

                  <div class="col-md-3">
                    <label for="forma-pagamento" class="form-label">
                      Forma de Pagamento
                    </label>
                    <select
                      id="tpay"
                      class="form-control"
                      onChange={(e) => this.handleChange(e)}
                      required=""
                    >
                      <option value="" disabled="" selected="">
                        Selecione
                      </option>
                      <option value="pne">Pagamento Na Entrega</option>
                      <option value="pnd">Pagamento Na Devolução</option>
                      <option value="cartao">Cartão de Crédito</option>
                      <option value="cartao">Cartão de Débito</option>
                      <option value="dinheiro">Dinheiro</option>
                      <option value="pix">PIX</option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="card-footer">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  var dados = this.state.dados;
                  if (this.state.dados.cliente == undefined) {
                    toast.warn("Selecione um cliente");
                    return;
                  }
                  if (this.state.products.length == 0) {
                    toast.warn("Selecione pelo menos um produto");
                    return;
                  }
                  if (this.state.data.type == undefined) {
                    toast.warn("Selecione um tipo de operação");
                    return;
                  }
                  if (this.state.dados.faturamento == undefined) {
                    toast.warn("Selecione um faturamento");
                    return;
                  }
                  if (this.state.dados.period == undefined) {
                    toast.warn("Selecione um periodo");
                    return;
                  }
                  if (this.state.dados.tpay == undefined) {
                    toast.warn("Selecione uma forma de pagamento");
                    return;
                  }

                  var send = {
                    type: this.state.data.type,
                    client: this.state.dados.cliente,
                    products: this.state.products,
                    seller: this.state.data.sellers.name,
                    location: {
                      billing: dados.faturamento,
                      period: dados.period,
                      start_day: dados.start_day,
                      end_day: EndDay,
                      location_value: total,
                      fine: this.state.dados.multa,
                      frete: this.state.dados.frete,
                      obs: this.state.dados.obs,
                      tpay: this.state.dados.tpay,
                    },
                  };

                  fetch(`${apiUrl}/new/location`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify(send),
                  })
                    .then((res) => {
                      if (res.status == 200) {
                        res.json().then((data) => {
                          toast.success("Locação adicionada com sucesso!");

                          if (this.state.data.type == "location") {
                            this.props.navigate(`/`);
                          } else if (this.state.data.type == "sale") {
                            this.props.navigate(`/sales-history`);
                          } else if (this.state.data.type == "o-l") {
                            this.props.navigate(`/budget`);
                          } else if (this.state.data.type == "o-v") {
                            this.props.navigate(`/budget/sales`);
                          }
                        });
                      } else {
                        toast.error("Ocorreu um erro ao adicionar a locação");
                      }
                    })
                    .catch((error) => {
                      toast.error("Ocorreu um erro ao adicionar a locação");
                      console.error(error);
                    });
                }}
              >
                <FontAwesomeIcon icon={faSave} /> Salvar
              </button>
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.addEquipament}
          title="Buscar equipamento"
          onAfterClose={(result) => {
            this.setState({ addEquipament: false, BuscaM: "", rBuscaM: [] });
          }}
        >
          <div className="content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.BuscaM.trim() == "") {
                  toast.warn("Digite algo para buscar");
                  e.target.classList.remove("load");
                  return;
                }

                fetch(`${apiUrl}/get-equipaments`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    name: this.state.BuscaM,
                    loca:
                      this.state.data.type == "location" ||
                      this.state.data.type == "o-l"
                        ? true
                        : false,
                  }),
                })
                  .then((res) => {
                    e.target.classList.remove("load");
                    if (res.status === 404) {
                      this.setState({ rBuscaM: null });

                      return null;
                    }
                    if (!res.ok) {
                      throw new Error("Erro ao buscar os dados");
                    }
                    return res.json();
                  })
                  .then((data) => {
                    if (data) {
                      this.setState({ rBuscaM: data });
                    }
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao buscar o cliente");
                    console.error(error);
                  });
              }}
            >
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Digite..."
                  onChange={(e) => this.setState({ BuscaM: e.target.value })}
                  value={this.state.BuscaM || ""}
                  className="form-control"
                />
                <button type="submit" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </form>

            <div className="mt-3">
              {this.state.rBuscaM ? (
                this.state.rBuscaM.length > 0 && (
                  <>
                    <table className="table table-striped table-bordered arvore-l">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Código de barra</th>
                          <th scope="col">Nome</th>
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rBuscaM.map((item, index) => (
                          <tr key={index}>
                            <td>{item.codigo_barra}</td>
                            <td>{item.name}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  console.log(this.state.products);
                                  console.log(item);
                                  const itemExists = this.state.products.some(
                                    (product) => product.id === item.id
                                  );

                                  if (itemExists) {
                                    toast.warn(
                                      "O equipamento já foi adicionado"
                                    );
                                  } else {
                                    this.setState({
                                      products: [
                                        ...this.state.products,
                                        {
                                          id: item.id,
                                          codigo_barra: item.codigo_barra,
                                          name: item.name,
                                          stockone: item.stockone,
                                          stocktwo: item.stocktwo,
                                          quantity: 1,
                                          value: item.value,
                                          price: {
                                            1: item.day_price,
                                            2: item.weekly_price,
                                            3: item.biweekly_price,
                                            4: item.monthly_price,
                                            5: item.annual_price,
                                          },
                                        },
                                      ],
                                      addEquipament: false,
                                      equipaments: [],
                                      rBuscaM: [],
                                      BuscaM: "",
                                    });
                                  }
                                }}
                              >
                                Selecionar
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )
              ) : (
                <div className="text-center">Nem um resultado encontrado</div>
              )}
            </div>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.showModal}
          title="Cadastrar cliente"
          onAfterClose={(result) => {
            this.setState({ showModal: false, rBusca: [], busca: "" });
          }}
        >
          <div className="content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.busca.trim() == "") {
                  toast.warn("Digite algo para buscar");
                  e.target.classList.remove("load");
                  return;
                }
                fetch(`${apiUrl}/buscar-cliente?s=${this.state.busca}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => {
                    e.target.classList.remove("load");
                    if (res.status === 404) {
                      this.setState({ rBusca: null });

                      return null;
                    }
                    if (!res.ok) {
                      throw new Error("Erro ao buscar os dados");
                    }
                    return res.json();
                  })
                  .then((data) => {
                    if (data) {
                      this.setState({ rBusca: data });
                    }
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao buscar o cliente");
                    console.error(error);
                  });
              }}
            >
              <div className="input-group">
                <InputMask
                  mask={this.state.mask}
                  placeholder="Digite..."
                  onChange={(e) => this.setState({ busca: e.target.value })}
                  value={this.state.busca || ""}
                  className="form-control"
                />
                <button type="submit" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </form>

            <div className="mt-3">
              {this.state.rBusca ? (
                this.state.rBusca.length > 0 && (
                  <>
                    <table class="table table-striped table-bordered arvore-l">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Nome</th>
                          <th scope="col">Documento</th>
                          <th>Status</th>
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rBusca.length > 0 &&
                          this.state.rBusca.map((item, index) => (
                            <>
                              <tr>
                                <td>{item.nome}</td>
                                <td>{item.cnpj_cpf}</td>
                                <td>{this.TypeBlock(item.status)}</td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      console.log(item);
                                      if (
                                        this.state.dados.type == "sale" ||
                                        item.status == 1
                                      ) {
                                        console.log("opa");
                                      }

                                      this.setState((prevState) => ({
                                        dados: {
                                          ...prevState.dados, // Mantém os dados existentes
                                          cliente: {
                                            id: item.id,
                                            nome: item.nome,
                                            cnpj_cpf: item.cnpj_cpf,
                                          }, // Adiciona a nova propriedade cliente
                                        },
                                        showModal: false,
                                        rBusca: [],
                                        busca: "",
                                      }));
                                    }}
                                  >
                                    Selecionar
                                  </button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </>
                )
              ) : (
                <div className="text-center">Nem um resultado encontrado</div>
              )}
            </div>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.newC}
          title="Cadastrar cliente"
          onAfterClose={(result) => {
            this.setState({
              newC: false,
              tpcad: null,
              selectedFiles: [],
              previews: [],
            });
          }}
        >
          {this.state.tpcad == null ? (
            <div
              style={{ display: "flex", justifyContent: "center", margin: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                  height: 100,
                  cursor: "pointer",
                  border: "1px solid black",
                }}
                onClick={() => {
                  this.setState({ tpcad: "cpf" });
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                  Cadastro de Pessoa Física
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                  height: 100,
                  cursor: "pointer",
                  marginLeft: 10,
                  border: "1px solid black",
                }}
                onClick={() => {
                  this.setState({ tpcad: "cnpj" });
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faStore} />
                  Cadatro de Empresa
                </div>
              </div>
            </div>
          ) : (
            <>
              {this.state.tpcad == "cpf" ? (
                <>
                  <div className="container">
                    <form
                      id="cadastroForm"
                      onSubmit={(e) => {
                        var dados = this.state.dados;
                        e.preventDefault();
                      }}
                    >
                      <div className="mt-3">
                        <div className="mb-2">
                          <label>Tipo de Cliente</label>
                        </div>
                        <select
                          className="form-control"
                          name="type_c"
                          onClick={() => this.handleChange}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Locação</option>
                          <option value="2">Venda</option>
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Nome"
                            name="nome"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>

                        <div className="col-md-6">
                          <TextField
                            label="Data de Nascimento"
                            name="data_nascimento"
                            type="date"
                            value={this.state.dados.data_nascimento || ""}
                            onChange={this.handleChange}
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cnpj_cpf"
                            onChange={this.handleChange}
                            mask="999.999.999-99" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Cpf"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="rg"
                            onChange={this.handleChange}
                            mask="99 999 999-9" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Rg"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Nome do Pai"
                            name="npai"
                            value={this.state.npai}
                            onChange={(e) => this.handleChange(e)}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Nome da Mae"
                            name="nmae"
                            value={this.state.nmae}
                            onChange={(e) => this.handleChange(e)}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="telefone"
                            onChange={this.handleChange}
                            mask="(99) 9999-9999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Telefone"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="celular"
                            onChange={this.handleChange}
                            mask="(99) 9 9999-9999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="WhatsApp"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>
                      <div>
                        <TextField
                          label="E-mail"
                          name="email"
                          onChange={this.handleChange}
                          variant="outlined" // Pode ser "filled" ou "standard"
                          fullWidth // O campo ocupa toda a largura disponível
                          margin="normal"
                          InputProps={{
                            style: {
                              height: 45,
                              padding: "0 5px",
                            },
                          }}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cep"
                            onChange={async (e) => {
                              const cep = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                              this.setState({ cep });

                              if (cep.length === 8) {
                                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                                  .then((response) => response.json())
                                  .then((data) => {
                                    if (data.error == "true") {
                                      toast.warning("CEP inválido");
                                    } else {
                                      this.setState((prevState) => ({
                                        dados: {
                                          ...prevState.dados, // Mantém os outros campos do objeto `dados`
                                          ["endereco"]: data.logradouro, // Atualiza apenas o campo correspondente
                                          ["bairro"]: data.bairro,
                                          ["cidade"]: data.localidade,
                                          ["estado"]: data.estado,
                                        },
                                      }));
                                    }
                                  })
                                  .catch((error) => {
                                    toast.warning("CEP inválido");
                                  });
                              }
                            }}
                            mask="99.999-999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="CEP:"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Endereço"
                            name="endereco"
                            value={this.state.dados.endereco || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Número"
                            name="numero"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Complemento"
                            name="complemento"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Bairro"
                            name="bairro"
                            onChange={this.handleChange}
                            value={this.state.dados.bairro || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Cidade"
                            name="cidade"
                            value={this.state.dados.cidade || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Estado"
                            name="estado"
                            value={this.state.dados.estado || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="mt-1">
                          <label className="mb-2">Documento:</label>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",

                              flexWrap: "wrap",
                            }}
                          >
                            {this.state.previews.map((src, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <img
                                  src={src}
                                  alt={`preview-${index}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                  }}
                                />
                                <button
                                  onClick={() => this.handleRemoveImage(index)}
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faMinus}
                                    color="#fff"
                                  />
                                </button>
                              </div>
                            ))}
                          </div>
                          <div
                            className="custom-upl"
                            style={{
                              marginTop:
                                this.state.previews.length > 0 ? "20px" : "0",
                            }}
                          >
                            <input
                              type="file"
                              multiple
                              accept="image/*,application/pdf" // Isso já ajuda a restringir para imagens no navegador
                              onChange={this.handleFileChange}
                            />
                            <button className="btn btn-primary">
                              <FontAwesomeIcon icon={faFileAlt} /> Anexar
                              Documento
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="container">
                    <form
                      id="cadastroForm"
                      onSubmit={(e) => {
                        var dados = this.state.dados;
                        e.preventDefault();
                      }}
                    >
                      <div className="mt-3">
                        <div className="mb-2">
                          <label>Tipo de Cliente</label>
                        </div>
                        <select
                          className="form-control"
                          name="type_c"
                          onClick={() => this.handleChange}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Locação</option>
                          <option value="2">Venda</option>
                        </select>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            label="Nome"
                            name="nome"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cnpj_cpf"
                            onChange={this.handleChange}
                            mask="99.999.999/9999-99" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Cnpj"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="inscricao_estadual"
                            onChange={this.handleChange}
                            mask="999.999.999.999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Inscrião Estadual"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="telefone"
                            onChange={this.handleChange}
                            mask="(99) 9999-9999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Telefone"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="celular"
                            onChange={this.handleChange}
                            mask="(99) 9 9999-9999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="WhatsApp"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>
                      <div>
                        <TextField
                          label="E-mail"
                          name="email"
                          onChange={this.handleChange}
                          variant="outlined" // Pode ser "filled" ou "standard"
                          fullWidth // O campo ocupa toda a largura disponível
                          margin="normal"
                          InputProps={{
                            style: {
                              height: 45,
                              padding: "0 5px",
                            },
                          }}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cep"
                            onChange={async (e) => {
                              const cep = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                              this.setState({ cep });

                              if (cep.length === 8) {
                                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                                  .then((response) => response.json())
                                  .then((data) => {
                                    if (data.error == "true") {
                                      toast.warning("CEP inválido");
                                    } else {
                                      this.setState((prevState) => ({
                                        dados: {
                                          ...prevState.dados, // Mantém os outros campos do objeto `dados`
                                          ["endereco"]: data.logradouro, // Atualiza apenas o campo correspondente
                                          ["bairro"]: data.bairro,
                                          ["cidade"]: data.localidade,
                                          ["uf"]: data.uf,
                                        },
                                      }));
                                    }
                                  })
                                  .catch((error) => {
                                    toast.warning("CEP inválido");
                                  });
                              }
                            }}
                            mask="99.999-999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="CEP:"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Endereço"
                            name="endereco"
                            value={this.state.dados.endereco || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Número"
                            name="numero"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Complemento"
                            name="complemento"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Bairro"
                            name="bairro"
                            onChange={this.handleChange}
                            value={this.state.dados.bairro || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Cidade"
                            name="cidade"
                            value={this.state.dados.cidade || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Estado"
                            name="estado"
                            value={this.state.dados.estado || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="mt-1">
                          <div className="mb-2">
                            <label>Documento</label>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",

                              flexWrap: "wrap",
                            }}
                          >
                            {this.state.previews.map((src, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <img
                                  src={src}
                                  alt={`preview-${index}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                  }}
                                />
                                <button
                                  onClick={() => this.handleRemoveImage(index)}
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faMinus}
                                    color="#fff"
                                  />
                                </button>
                              </div>
                            ))}
                          </div>
                          <div
                            className="custom-upl"
                            style={{
                              marginTop:
                                this.state.previews.length > 0 ? "20px" : "0",
                            }}
                          >
                            <input
                              type="file"
                              multiple
                              accept="image/*,application/pdf" // Isso já ajuda a restringir para imagens no navegador
                              onChange={this.handleFileChange}
                            />
                            <button className="btn btn-primary">
                              <FontAwesomeIcon icon={faFileAlt} /> Anexar
                              Documento
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </>
          )}
          {this.state.tpcad != null && (
            <>
              <div className="modal-fotter">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    var dados = this.state.dados;

                    if (this.state.tpcad == "cpf") {
                      if (dados.data_nascimento == undefined) {
                        toast.warn("Selecione uma data de nascimento");
                        return;
                      }
                    }

                    const formData = new FormData();

                    // Adiciona os dados do formulário ao FormData
                    formData.append("nome", dados.nome);
                    if (this.state.tpcad == "cpf") {
                      formData.append(
                        "data_nascimento",
                        this.state.dados.data_nascimento
                      );
                      formData.append("rg", this.state.dados.rg);
                      formData.append("npai", this.state.dados.npai);
                      formData.append("nmae", this.state.dados.nmae);
                      formData.append(
                        "complemento",
                        this.state.dados.complemento
                      );
                    } else {
                      formData.append(
                        "inscricao_estadual",
                        this.state.dados.inscricao_estadual
                      );
                    }
                    formData.append("cnpj_cpf", dados.cnpj_cpf);
                    formData.append("email", dados.email);
                    formData.append("telefone", dados.telefone);
                    formData.append("celular", dados.celular);
                    formData.append("cep", dados.cep);
                    formData.append("endereco", dados.endereco);
                    formData.append("numero", dados.numero);
                    formData.append("bairro", dados.bairro);
                    formData.append("cidade", dados.cidade);
                    formData.append("estado", dados.estado);

                    // Verifique se há uma imagem e adicione ao FormData

                    this.state.selectedFiles.forEach((file) => {
                      formData.append("images", file);
                    });

                    fetch(`${apiUrl}/cadastrar-cliente`, {
                      method: "POST",

                      body: formData,
                    })
                      .then((response) => {
                        if (response.status == 200) {
                          response.json().then((data) => {
                            this.setState((prevState) => ({
                              dados: {
                                ...prevState.dados, // Mantém os dados existentes
                                cliente: {
                                  id: data.id,
                                  nome: dados.nome,
                                  cnpj_cpf: dados.cnpj_cpf,
                                }, // Adiciona a nova propriedade cliente
                              },
                              newC: false,
                              tpcad: null,
                            }));
                            toast.success("Cliente adicionado");
                          });
                        }
                      })
                      .catch((err) => {
                        toast.error("Falha na comunicação com o servidor!");
                      });
                  }}
                >
                  <FontAwesomeIcon icon={faSave} /> Salvar
                </button>
              </div>
            </>
          )}
        </StaticDialog>
      </div>
    );
  }
}

export default Lease;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../../img/atencao.svg";
import logo from "../../../img/logo.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faClipboard,
  faCoffee,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFileText,
  faFlag,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMobileAlt,
  faPen,
  faPhone,
  faPrint,
  faSave,
  faSearch,
  faTrash,
  faUser,
  faUserPlus,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";
import { apiUrl } from "../../../comps/apiUrl";
import { toast } from "react-toastify";

class LeaseHistoy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: {},
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      edit: {},
      trash: {},
      viewProducts: {},
      comprovante: {},
      blockUser: {},
    };
  }

  componentDidMount() {
    document.title = "Histórico de locações";
    this.fetchData(this.state.currentPage);
  }

  fetchData(page) {
    fetch(`${apiUrl}/leasers?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.status == 510) {
        localStorage.clear();
        window.location.href = "/";
        return;
      }

      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }
  Payment_type(t) {
    if (t == "boletop") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Pendente
        </span>
      );
    } else if (t == "boletoa") {
      return (
        <span class="badge bg-success" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Aprovado
        </span>
      );
    } else if (t == "pix") {
      return <span class="badge bg-success">Pix</span>;
    } else if (t == "boleto") {
      return <span class="badge bg-success">Boleto</span>;
    } else if (t == "cartaod") {
      return <span class="badge bg-success">Cartão de debito</span>;
    } else if (t == "cartaoc") {
      return <span class="badge bg-success">Cartão de credito</span>;
    } else if (t == "dinheiro") {
      return <span class="badge bg-success">Dinheiro</span>;
    } else if (t == "pne") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Entrega
        </span>
      );
    } else if (t == "pnd") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Devoluão
        </span>
      );
    }
  }
  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };
  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
  }
  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleCpfCnpjChange = (e) => {
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;
    var value = e.target.value;
    if (e.target.value.length <= 14) {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cpf(value), // Atualiza apenas o campo correspondente
        },
      }));
    } else {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cnpj(value), // Atualiza apenas o campo correspondente
        },
      }));
    }
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };

    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };
    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Locações</strong>
            </div>
            <div class="card-body">
              {this.state.items.length > 0 ? (
                <>
                  <div class="mb-3 d-flex justify-content-center">
                    <div class="input-group w-50">
                      <input
                        type="text"
                        class="form-control"
                        id="searchInput"
                        onChange={(e) => {
                          this.setState({ search: e.target.value });
                        }}
                        placeholder="Pesquisar..."
                      />
                      <button
                        class="btn btn-primary"
                        onClick={(e) => {
                          console.log("aaaa");
                          e.target.classList.add("load");
                          var search = this.state.search;
                          fetch(`${apiUrl}/buscar-cliente?s=${search}`, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                              authorization: localStorage.getItem("token"),
                            },
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              e.target.classList.remove("load");
                            });
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                  <table class="table table-striped table-bordered arvore-l">
                    <thead class="thead-dark">
                      <tr>
                        <th>ID do Cliente</th>

                        <th>Cliente</th>
                        <th>Faturamento</th>

                        <th>Valor Locação</th>
                        <th>Locação</th>
                        <th>Vencimento</th>
                        <th>Devolução</th>
                        <th>Status</th>
                        <th>Data de Pagamento</th>

                        <th>Metodo de Pagamento</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.id}</td>

                            <td>
                              <Link
                                to={`/cadastro-clientes/view/${item.client_id}`}
                              >
                                {item.client_name}
                              </Link>
                            </td>
                            <td>{invoicingType[item.billing]}</td>
                            <td>
                              {item.location_value.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>

                            <td>{this.formatarDataBR(item.start_day)}</td>
                            <td>{this.formatarDataBR(item.end_day)}</td>
                            <td>
                              {item.payment_a == null
                                ? "---"
                                : this.formatarDataBR(item.payment_a)}
                            </td>
                            <td>
                              <span className="badge bg-success">
                                Devolvido
                              </span>
                            </td>
                            <td>{this.formatarDataBR(item.return_day)}</td>

                            <td>{this.Payment_type(item.payment_type)}</td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <Link
                                  className="btn btn-primary"
                                  to={`/lease/view/${item.token}`}
                                >
                                  <FontAwesomeIcon icon={faSearch} />
                                </Link>
                                <Link
                                  className="btn btn-primary"
                                  style={{ marginLeft: 10, marginRight: 10 }}
                                  to={`/lease/contract/${item.token}`}
                                >
                                  <FontAwesomeIcon icon={faPrint} />
                                </Link>

                                <Link
                                  target="_blank"
                                  className="btn btn-primary"
                                  style={{ marginRight: 10 }}
                                  to={`/lease/receipt/${item.token}`}
                                >
                                  <FontAwesomeIcon icon={faFileAlt} />
                                </Link>

                                <button
                                  className="btn btn-danger"
                                  onClick={() => {
                                    console.log(item);
                                    this.setState({
                                      blockUser: item,
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faUserTimes} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Não há resultados</>
              )}
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.renderPagination()}
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.blockUser).length > 0}
          title="Bloquear Cliente"
          onAfterClose={(result) => {
            this.setState({ blockUser: {} });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              var form = e.target;

              fetch(`${apiUrl}/block-user`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify({
                  id: this.state.blockUser.client_id,
                  status: this.state.blockUser.client_status,
                  obs: this.state.blockUser.obs,
                }),
              }).then((response) => {
                if (response.status === 200) {
                  toast.success("Bloqueado com sucesso!");
                  this.setState({ blockUser: {} });
                }
              });
            }}
          >
            <div style={{ margin: 10 }}>
              <div className="mt-2 mb-3">
                status
                <select
                  className="form-control mt-3"
                  onChange={(e) => {
                    this.setState({
                      blockUser: {
                        ...this.state.blockUser,
                        client_status: e.target.value,
                      },
                    });
                  }}
                  value={this.state.blockUser.client_status}
                >
                  <option>Selecione:</option>
                  <option value="0">Desbloqueado</option>
                  <option value="1">Bloqueado Para Locação</option>
                  <option value="2">Bloqueado Para Serviço</option>
                  <option value="3">Bloqueio Total</option>
                </select>
              </div>
              <div className="mt-2 mb-3">
                <textarea
                  class="form-control"
                  placeholder="Observação"
                  onChange={(e) => {
                    this.setState({
                      blockUser: {
                        ...this.state.blockUser,
                        obs: e.target.value,
                      },
                    });
                  }}
                  value={this.state.blockUser.obs || ""}
                  style={{ height: "100px" }}
                />
              </div>
            </div>
            <div className="modal-fotter">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faSave} /> Salvar
              </button>
            </div>
          </form>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.comprovante).length > 0}
          title="Informações de Devolução"
          onAfterClose={(result) => {
            this.setState({ comprovante: {} });
          }}
        >
          <div className="content">
            <div class="mb-3">
              <label for="nome-cliente" class="form-label">
                Nome do Cliente
              </label>
              <input
                type="text"
                id="nome-cliente"
                class="form-control"
                value={this.state.comprovante.n_cliente}
                disabled
              />
            </div>
            <div>
              <textarea
                class="form-control"
                placeholder="Observação"
                style={{ height: "100px" }}
              />
            </div>
          </div>
          <div className="modal-fotter">
            <button className="btn btn-primary">
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default LeaseHistoy;

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faClipboard,
  faCoffee,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFileText,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMobileAlt,
  faPen,
  faPhone,
  faPrint,
  faRefresh,
  faSave,
  faSearch,
  faTrash,
  faUser,
  faUserPlus,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";

import { toast } from "react-toastify";
import { apiUrl } from "../../comps/apiUrl";
import withLocation from "../../comps/withLocation";

class ReNewLeaser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      data: [],
      dados: [],
      renew: [],
    };
  }

  componentDidMount() {
    document.title = "Renovar Locação";
    fetch(`${apiUrl}/view-leaser/${this.props.params.token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.status == 510) {
        localStorage.clear();
        window.location.href = "/";
        return;
      }

      response.json().then((data) => {
        this.setState({
          data: data,
          renew: data.products,
          loader: false,
        });
      });
    });
  }

  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano}`;
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  endDay() {
    var billing = {
      day_price: 1,
      weekly_price: 7,
      biweekly_price: 15,
      monthly_price: 30,
      annual_price: 365,
    };
    const date = new Date(this.state.data.end_day);
    var dias =
      this.state.dados.period !== undefined
        ? this.state.dados.period * billing[this.state.dados.faturamento]
        : 0;
    date.setDate(date.getDate() + dias);

    // Construção manual do formato 'YYYY-MM-DD HH:mm'
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    var product = this.state.data.products;
    var invoicingType = {
      day_price: "Diária",
      weekly_price: "Semanal",
      biweekly_price: "Quizenal",
      monthly_price: "Mensal",
      annual_price: "Anual",
    };

    var endDay = this.endDay();
    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Renovar Locação</strong>
            </div>
            <div class="card-body">
              <table className="table table-striped table-bordered arvore-l mt-2">
                <thead className="thead-dark">
                  <tr>
                    <th>Código de Barras</th>
                    <th>Nome</th>
                    <th>Faturamento</th>
                    <th>Quantidade</th>
                    <th>Valor Unitário</th>
                    <th>Valor Total</th>
                  </tr>
                </thead>
                <tbody>
                  {product.map((product) => {
                    return (
                      <tr key={product.codigo_barra}>
                        <td>{product.codigo_barra}</td>
                        <td>{product.name}</td>
                        <td>{invoicingType[this.state.data.rental_period]}</td>
                        <td>{product.quantiny}</td>
                        <td>
                          {product.day_price.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                        <td>
                          {(
                            product.quantiny * product.day_price
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                    );
                  })}

                  {/* Linha do valor total */}
                  <tr>
                    <td colspan="5"></td>
                    <td style={{ textAlign: "center" }}>
                      {/* Cálculo do valor total */}
                      {product
                        .reduce((total, product) => {
                          return total + product.quantiny * product.day_price;
                        }, 0)
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row mb-3">
                <div class="col-md-2">
                  <label for="faturamento" class="form-label">
                    Faturamento
                  </label>
                  <select
                    className="form-control"
                    id="faturamento"
                    name="faturamento"
                    onChange={(e) => {
                      if (e.target.value == "0") {
                        e.preventDefault();
                        return false;
                      }
                      this.handleChange(e);
                    }}
                    value={this.state.dados.faturamento || ""}
                  >
                    <option value="0">Selecione</option>
                    <option value="day_price">Diária</option>
                    <option value="weekly_price">Semanal</option>
                    <option value="biweekly_price">Quinzenal</option>
                    <option value="monthly_price">Mensal</option>
                    <option value="annual_price">Anual</option>
                  </select>
                </div>

                <div class="col-md-2">
                  <label for="period" class="form-label">
                    Qtd. Período Adicional
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => {
                      var value = e.target.value.replace(/\D/g, "");

                      this.setState((prevState) => ({
                        dados: {
                          ...prevState.dados,
                          ["period"]: new Number(value),
                        },
                      }));
                    }}
                    value={this.state.dados.period || 0}
                    disabled={0}
                  />
                </div>

                <div class="col-md-2">
                  <label for="data-inicial" class="form-label">
                    Data Inicial
                  </label>
                  <input
                    type="datetime-local"
                    id="data-inicial"
                    class="form-control"
                    required=""
                    value={this.state.data.start_day.slice(0, 16)}
                    disabled={true}
                  />
                </div>

                <div class="col-md-3">
                  <label for="data-final-renovacao" class="form-label">
                    Data da Renovação
                  </label>
                  <input
                    type="datetime-local"
                    id="data-final-renovacao"
                    class="form-control"
                    value={this.state.data.end_day.slice(0, 16)}
                    required=""
                    disabled={true}
                  />
                </div>

                <div class="col-md-3">
                  <label for="data-final" class="form-label">
                    Data Final - {endDay}
                  </label>
                  <input
                    type="datetime-local"
                    id="data-final"
                    class="form-control"
                    value={
                      this.state.dados.faturamento !== undefined &&
                      this.state.dados.period > 0
                        ? endDay
                        : 0
                    }
                    required=""
                    disabled={true}
                  />
                </div>
              </div>

              <div class="row mb-3 d-flex align-items-center"></div>

              {this.state.dados.faturamento !== undefined &&
                this.state.dados.period > 0 && (
                  <table className="table table-striped table-bordered arvore-l mt-2">
                    <thead className="thead-dark">
                      <tr>
                        <th>Código de Barras</th>
                        <th>Nome</th>
                        <th>Faturamento</th>
                        <th>Valor Unitário</th>
                        <th>Valor Total</th> {/* Nova coluna "Valor Total" */}
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.renew.map((product) => {
                        // Acessa o preço de acordo com o tipo de faturamento
                        const unitPrice = product[this.state.dados.faturamento];

                        // Ajusta o preço de acordo com o período
                        const adjustedPrice =
                          unitPrice * this.state.dados.period;

                        // Calcula o valor total (quantidade * preço ajustado)
                        const totalValue = adjustedPrice * product.quantiny;

                        return (
                          <tr key={product.codigo_barra}>
                            <td className={product.block === 1 ? "dsb" : ""}>
                              {product.codigo_barra}
                            </td>
                            <td className={product.block === 1 ? "dsb" : ""}>
                              {product.name}
                            </td>
                            <td className={product.block === 1 ? "dsb" : ""}>
                              {invoicingType[this.state.dados.faturamento]}
                            </td>
                            <td className={product.block === 1 ? "dsb" : ""}>
                              {product[
                                this.state.dados.faturamento
                              ].toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                            <td className={product.block === 1 ? "dsb" : ""}>
                              {totalValue.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                            <td>
                              <button
                                className={
                                  product.block === 1
                                    ? "btn btn-primary"
                                    : "btn btn-danger"
                                }
                                onClick={() => {
                                  var id = product.id;

                                  this.setState((prevState) => ({
                                    renew: prevState.renew.map((item) => {
                                      if (item.id === id) {
                                        // Se o campo idade não existir, criamos com o valor 1
                                        if (item.block === undefined) {
                                          return { ...item, block: 1 };
                                        }
                                        // Caso contrário, alternamos entre 1 e 0
                                        return {
                                          ...item,
                                          block: item.block === 1 ? 0 : 1,
                                        };
                                      }
                                      return item;
                                    }),
                                  }));
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    product.block === 1 ? faRefresh : faTrash
                                  }
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colspan="4"></td>
                        <td style={{ textAlign: "center" }}>
                          {/* Cálculo do valor total de todos os produtos */}

                          {this.state.renew
                            .reduce((total, product) => {
                              if (product.block === 1) {
                                return total; // Se block for 1, não adiciona o valor ao total
                              }
                              const unitPrice =
                                product[this.state.dados.faturamento];
                              const adjustedPrice =
                                unitPrice * this.state.dados.period;
                              const totalValue =
                                adjustedPrice * product.quantiny;
                              return total + totalValue;
                            }, 0)
                            .toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="btn btn-primary"
                disabled={
                  this.state.dados.faturamento !== undefined &&
                  this.state.dados.period > 0
                    ? false
                    : true
                }
                onClick={(e) => {
                  var total = this.state.renew.reduce((total, product) => {
                    if (product.block === 1) {
                      return total;
                    }
                    const unitPrice = product[this.state.dados.faturamento];
                    const adjustedPrice = unitPrice * this.state.dados.period;
                    const totalValue = adjustedPrice * product.quantiny;
                    return total + totalValue;
                  }, 0);

                  if (total === 0) {
                    toast.warn("Nenhum produto selecionado");
                    return;
                  }
                  e.target.classList.add("load");
                  const filteredProducts = this.state.renew.filter(
                    (product) => product.block !== 1
                  );

                  fetch(`${apiUrl}/renew-leaser/${this.props.params.token}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                      dados: {
                        faturamento: this.state.dados.faturamento,
                        period: this.state.dados.period,
                        total: total.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }),
                        end_day: this.endDay(),
                      },
                      products: filteredProducts,
                    }),
                  }).then((response) => {
                    e.target.classList.remove("load");
                    this.props.navigate("/");
                  });
                }}
              >
                <FontAwesomeIcon icon={faSave} /> Salvar
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withLocation(ReNewLeaser);

import React, { Component } from "react";
import { apiUrl } from "../../../comps/apiUrl";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faFile,
  faFileAlt,
  faPen,
  faPrint,
  fawhatsapp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import alert from "../../../img/alert.svg";
import logomarca from "../../../img/logomarca.jpg";
import withLocation from "../../../comps/withLocation";
class SaleReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loader: true,
      data: [],
      store: {},
      client: {},
      location: {},
      products: {},
    };
  }

  componentDidMount() {
    document.title = "Visualizar Venda";
    fetch(`${apiUrl}/lease/contract/${this.props.params.token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.setState({
            loader: false,
            error: true,
          });
        }
        this.setState({
          data: data,
          store: data.store,
          client: data.client,
          location: data.location,
          products: data.products,
          loader: false,
        });
        setTimeout(() => {
          //window.print();
        }, 100);
      })

      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    const seconds = String(data.getSeconds()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${seconds}`;
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "80vh",
          }}
        >
          <img
            src={alert}
            style={{ width: "400px", top: 100 }}
            alt="imagem de alerta"
          />
          <div>
            Ops! O documento que você está procurando não foi encontrado.
          </div>
        </div>
      );
    }
    var data = this.state.data;
    var typeBiling = data.location.billing;
    var store = this.state.store;
    var client = this.state.client;
    var location = this.state.location;
    var products = this.state.products;
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };
    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };
    if (this.state.loader == false) {
      return (
        <div className="receipt">
          <div
            style={styles.body}
            className="receipt"
            align="center"
            id="docPrint"
          >
            <div className="container-fluid" style={styles.container}>
              <div className="row">
                <div className="col-md-12" style={{ textAlign: "center" }}>
                  <img
                    src="/webcontrol/logomarca/77180/logomarca_77180.jpg"
                    height="35px"
                    alt="Logomarca"
                  />
                </div>
                <div className="col-md-12">
                  <b>
                    {this.state.store.name}
                    <br />
                    {this.state.store.address}
                    <br />
                    {this.state.store.city} - {this.state.store.mobile}
                    <br />
                    {this.state.store.cnpj}
                  </b>
                </div>
              </div>

              <div className="row" style={styles.sectionTitle}>
                <b>RECIBO DE LOCAÇÃO</b>
              </div>

              <div style={styles.infoSection}>
                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Cliente: {this.state.client.nome}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>CPF/CNPJ: {this.state.client.documento}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          Telefone:{" "}
                          {this.state.client.telefone == null
                            ? ""
                            : this.state.client.telefone}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          Celular:{" "}
                          {this.state.client.celular == null
                            ? ""
                            : this.state.client.celular}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Endereço: {this.state.client.endereco}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bairro: {this.state.client.bairro}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Cidade: {this.state.client.cidade}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="">
                <table style={styles.table}>
                  <thead>
                    <tr>
                      <th>
                        <b>Item Cod. Produto</b>
                      </th>
                    </tr>
                    <tr>
                      <th style={styles.dashedBorder}>
                        <b>
                          Qtd x Vr Unit{" "}
                          <span style={styles.pullRight}>Total</span>
                        </b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products.map((product, index) => (
                      <>
                        <tr>
                          <td>
                            <b>
                              {(index + 1).toString().padStart(3, "0")}{" "}
                              {product.codigo_barra} {product.name}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.dashedBorder}>
                            <b>
                              &nbsp;&nbsp;&nbsp;{product.quantiny} x 0
                              <span style={styles.pullRight}> 0</span>
                            </b>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>

              <table style={styles.table}>
                <tbody>
                  <tr>
                    <td>
                      <b>=&gt; SubTotal: </b>
                      <span style={styles.pullRight}>
                        <b>
                          {this.state.location.location_value.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                        </b>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.dashedBorder}>
                      <b>=&gt; Valor a Pagar: </b>
                      <span style={styles.pullRight}>
                        <b>
                          {this.state.location.location_value.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                        </b>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="row" style={styles.paymentSection}>
                <div className="col-md-12">
                  <b>FORMA DE PAGAMENTO</b>
                </div>
              </div>
              <table style={{ width: "250px", margin: "0" }}>
                <tbody>
                  <tr>
                    <td
                      className="col-md-12"
                      style={{ textAlign: "left", paddingLeft: "30px" }}
                    >
                      <b>
                        Atendente/Vendedor: {this.state.location.seller_name}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="col-md-12"
                      style={{ textAlign: "left", paddingLeft: "0" }}
                    >
                      <b>
                        Data/Hora Venda:{" "}
                        {this.formatarDataBR(this.state.location.created_at)}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="col-md-12"
                      style={{ textAlign: "left", paddingLeft: "0" }}
                    >
                      <b>
                        Data/Hora Atualização: {this.formatarDataBR(new Date())}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="col-md-12"
                      style={{ textAlign: "left", paddingLeft: "0" }}
                    >
                      <b>Observação: {this.state.location.obs}</b>
                    </td>
                  </tr>
                </tbody>
                <p
                  style={{
                    borderTop: "1px solid black",
                    marginTop: "40px",
                    marginBottom: "40px",
                  }}
                >
                  Assinatura:
                </p>
              </table>
            </div>
          </div>
        </div>
      );
    }
  }
}
const styles = {
  body: {
    margin: "25px 0.5px 3px 0.5px",
    fontFamily: "Courier New, Courier, monospace",
    fontSize: "10px",
    letterSpacing: "-1px",
  },
  container: {
    padding: "0px 3.6%",
  },
  sectionTitle: {
    width: "250px",
    margin: "5px 0",
    fontSize: "12px",
    padding: "5px",
    borderTop: "1px dashed #000",
    borderBottom: "1px dashed #000",
  },
  infoSection: {
    width: "250px",
    margin: "5px 0",
    borderBottom: "1px dashed #000",
    paddingBottom: "5px",
  },
  table: {
    width: "250px",
    border: "none",
  },
  dashedBorder: {
    borderBottom: "1px dashed #000",
    paddingBottom: "5px",
  },
  pullRight: {
    float: "right",
  },
  paymentSection: {
    width: "250px",
    padding: "5px 0",
    borderBottom: "1px dashed #000",
  },
};
export default withLocation(SaleReceipt);

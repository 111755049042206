import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../img/atencao.svg";
import logo from "../../img/logo.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCalendar,
  faCheck,
  faClipboard,
  faCoffee,
  faCog,
  faEnvelope,
  faEye,
  faFile,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFileText,
  faFlag,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMobileAlt,
  faPen,
  faPhone,
  faPrint,
  faSave,
  faSearch,
  faTrash,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
class CurrentLease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: {},
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      comprovante: {},
      edit: {},
      trash: {},
      viewProducts: {},
      comprovante: {},
      pay: {},
      boletop: {},
      selectedFile: [],
      master: undefined,
      delmaster: {},
      f: "1",
    };
  }

  componentDidMount() {
    document.title = "Histórico de locações";
    this.fetchData(this.state.currentPage);
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      this.setState({ master: decoded.role });
    }
  }
  Payment_type(t) {
    if (t == "boletop") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Pendente
        </span>
      );
    } else if (t == "boletoa") {
      return (
        <span class="badge bg-success" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Aprovado
        </span>
      );
    } else if (t == "pix") {
      return <span class="badge bg-success">Pix</span>;
    } else if (t == "boleto") {
      return <span class="badge bg-success">Boleto</span>;
    } else if (t == "cartaod") {
      return <span class="badge bg-success">Cartão de debito</span>;
    } else if (t == "cartaoc") {
      return <span class="badge bg-success">Cartão de credito</span>;
    } else if (t == "dinheiro") {
      return <span class="badge bg-success">Dinheiro</span>;
    } else if (t == "pne") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Entrega
        </span>
      );
    } else if (t == "pnd") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Devoluão
        </span>
      );
    }
  }
  fetchData(page) {
    fetch(
      `${apiUrl}/current-lease?page=${page}&limit=${this.state.itemsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      if (response.status === 510) {
        localStorage.clear();
        window.location.href = "/";
      }

      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }
  handleFileChange = (e) => {
    const file = e.target.files[0]; // Obter o arquivo selecionado

    if (file) {
      // Verificar o tipo de arquivo
      const isImage = file.type.startsWith("image/");

      if (isImage) {
        const files = Array.from(e.target.files); // Converte para array

        this.setState({ selectedFile: files });
      } else {
        // Se não for uma imagem, mostrar erro
        toast.error("Por favor, selecione apenas imagens.");
        e.target.value = null;
        this.setState({ selectedFile: null }); // Limpar o arquivo selecionado
      }
    }
  };
  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };
  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    const secounds = String(data.getSeconds()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${secounds}`;
  }
  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleCpfCnpjChange = (e) => {
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;
    var value = e.target.value;
    if (e.target.value.length <= 14) {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cpf(value), // Atualiza apenas o campo correspondente
        },
      }));
    } else {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cnpj(value), // Atualiza apenas o campo correspondente
        },
      }));
    }
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };

    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };
    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Locações</strong>
            </div>
            <div class="card-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  var itens = e.target;

                  if (itens[0].value == "") {
                    toast.warning("Selecione um filtro");
                    return;
                  }
                  if (itens[1].value.trim().length == 0) {
                    toast.warning("Preencha o campo de pesquisa");
                    return;
                  }

                  fetch(`${apiUrl}/buscar-locacao`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                      filter: itens[0].value,
                      search: itens[1].value,
                    }),
                  }).then((response) => {
                    itens[1].classList.remove("load");
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState({ items: data });
                      });
                    }
                  });
                }}
              >
                <div class="mb-3 d-flex justify-content-center">
                  <div class="input-group w-50">
                    <select
                      class="form-select"
                      onChange={(e) => {
                        this.setState({ f: e.target.value });
                      }}
                    >
                      <option value="">Selecione:</option>
                      <option value="1">Cliente</option>
                      <option value="2">Faturamento</option>
                      <option value="3">Data da Locação</option>
                      <option value="4">Data de Vencimento</option>

                      <option value="6">Data de Pagamento</option>
                      <option value="7">Metodo de Pagamento</option>
                    </select>

                    {this.state.f == 2 ||
                    this.state.f == 5 ||
                    this.state.f == 7 ? (
                      <>
                        {this.state.f == 2 && (
                          <>
                            <select
                              class="form-control"
                              id="faturamento"
                              name="faturamento"
                              onChange={(e) => {
                                this.setState({ search: e.target.value });
                              }}
                            >
                              <option value="0">
                                Selecione o período do faturamento
                              </option>
                              <option value="1">Diária</option>
                              <option value="2">Semanal</option>
                              <option value="3">Quinzenal</option>
                              <option value="4">Mensal</option>
                              <option value="5">Anual</option>
                            </select>
                          </>
                        )}
                        {this.state.f == 7 && (
                          <>
                            <select
                              name="tpay"
                              class="form-control"
                              onChange={(e) =>
                                this.setState({ search: e.target.value })
                              }
                            >
                              <option value="">Selecione</option>
                              <option value="pne">Pagamento Na Entrega</option>
                              <option value="pnd">
                                Pagamento Na Devolução
                              </option>
                              <option value="boletop">Boleto</option>
                              <option value="cartaoc">Cartão de Crédito</option>
                              <option value="cartaod">Cartão de Débito</option>
                              <option value="dinheiro">Dinheiro</option>
                              <option value="pix">PIX</option>
                            </select>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <input
                          type={
                            this.state.f == 3 ||
                            this.state.f == 4 ||
                            this.state.f == 6
                              ? "date"
                              : "text"
                          }
                          autoComplete="off"
                          class="form-control"
                          id="searchInput"
                          onChange={(e) => {
                            this.setState({ search: e.target.value });
                          }}
                          placeholder="Pesquisar..."
                        />
                      </>
                    )}

                    <button type="submit" class="btn btn-primary">
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
              </form>
              {this.state.items.length > 0 ? (
                <>
                  <table class="table table-striped table-bordered arvore-l">
                    <thead class="thead-dark">
                      <tr>
                        <th>ID do Cliente</th>
                        <th>Funcionário(a)</th>
                        <th>Cliente</th>
                        <th>Faturamento</th>
                        <th>Valor Locação</th>
                        <th>Locação</th>
                        <th>Vencimento</th>
                        <th>Data de Pagamento</th>
                        <th>Status</th>

                        <th>Metodo de Pagamento</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.seller_name}</td>
                            <td>
                              <Link
                                to={`/cadastro-clientes/view/${item.client_id}`}
                                style={{ textDecoration: "none" }}
                              >
                                {item.client_name}
                              </Link>
                            </td>
                            <td>{invoicingType[item.billing]}</td>
                            <td>
                              {item.location_value.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>

                            <td>{this.formatarDataBR(item.start_day)}</td>
                            <td>
                              {item.renew.length > 0 ? (
                                <>
                                  <s>{this.formatarDataBR(item.end_day)}</s> -{" "}
                                  {this.formatarDataBR(item.renew[0].end_day)}
                                </>
                              ) : (
                                <>{this.formatarDataBR(item.end_day)}</>
                              )}
                            </td>
                            <td>
                              {item.payment_a == null
                                ? "---"
                                : this.formatarDataBR(item.payment_a)}
                            </td>

                            <td>
                              <span
                                class="badge"
                                style={{
                                  background: item.order.bg,
                                  color: item.order.color,
                                }}
                              >
                                {item.order.message}
                              </span>
                            </td>

                            <td>
                              {item.payment_type == "pnd" ||
                              item.payment_type == "pne" ? (
                                <div
                                  onClick={(e) => {
                                    this.setState({ pay: item });
                                  }}
                                >
                                  {this.Payment_type(item.payment_type)}
                                </div>
                              ) : item.payment_type == "boletop" ? (
                                <div
                                  onClick={(e) => {
                                    this.setState({ boletop: item });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span class="badge bg-danger">
                                    Boleto Pendente
                                  </span>
                                </div>
                              ) : (
                                this.Payment_type(item.payment_type)
                              )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <Link
                                  to={`/location/details/${item.token}`}
                                  style={{ marginRight: "10px" }}
                                  className="btn btn-primary"
                                >
                                  <FontAwesomeIcon icon={faSearch} />
                                </Link>

                                <Link
                                  to={`/lease/renew-location/${item.token}`}
                                  className="btn btn-primary"
                                >
                                  <FontAwesomeIcon icon={faCalendar} />
                                </Link>
                                <button
                                  className="btn"
                                  style={{
                                    marginLeft: "10px",
                                    background: "#8A2BE2",
                                  }}
                                  onClick={() => {
                                    this.props.navigate(
                                      `/lease/contract/${item.token}`
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    color="#fff"
                                    icon={faPrint}
                                  />
                                </button>
                                <button
                                  className="btn btn-success"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    this.setState({ comprovante: item });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                </button>
                                {this.state.master != undefined && (
                                  <div style={{ marginLeft: "10px" }}>
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        console.log("teste");
                                        this.setState({ delmaster: item });
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Não há resultados</>
              )}
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.renderPagination()}
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.pay).length > 0}
          title="Informações de pagamento"
          onAfterClose={(result) => {
            this.setState({ pay: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <div>
              <label>Forma de Pagamento</label>
              <select
                id="tpay"
                class="form-control mt-2"
                onChange={(e) => {
                  this.setState({ payment_type: e.target.value });
                }}
                required=""
              >
                <option value="" disabled="" selected="">
                  Selecione
                </option>

                <option value="cartaoc">Cartão de Crédito</option>
                <option value="cartaod">Cartão de Débito</option>
                <option value="dinheiro">Dinheiro</option>
                <option value="pix">PIX</option>
              </select>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  this.state.payment_type == "" ||
                  this.state.payment_type == undefined
                ) {
                  toast.warn("Por favor, selecione a forma de pagamento!");
                  return;
                }

                e.target.classList.add("load");
                var payment_type = this.state.payment_type;
                var token = this.state.pay.token;
                fetch(`${apiUrl}/lease/${this.state.pay.token}/payment`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    payment_type,
                  }),
                }).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status === 200) {
                    this.setState((prevState) => ({
                      items: prevState.items.map((produto) =>
                        produto.token === token
                          ? { ...produto, payment_type, payment_a: new Date() }
                          : produto
                      ),
                      pay: {},
                    }));
                    toast.success("Pagamento efetuado com sucesso!");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.boletop).length > 0}
          title="Informações de pagamento"
          onAfterClose={(result) => {
            this.setState({ boletop: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <div>
              <label>Forma de Pagamento</label>
              <select
                id="tpay"
                class="form-control mt-2"
                onChange={(e) => {
                  this.setState({ payment_type: e.target.value });
                }}
                required=""
              >
                <option value="" disabled="" selected="">
                  Selecione
                </option>
                <option value="boletoa">Boleto Aprovado</option>
              </select>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  this.state.payment_type == "" ||
                  this.state.payment_type == undefined
                ) {
                  toast.warn("Por favor, selecione a forma de pagamento!");
                  return;
                }

                e.target.classList.add("load");
                var payment_type = this.state.payment_type;
                var token = this.state.boletop.token;
                fetch(`${apiUrl}/lease/${this.state.boletop.token}/payment`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    payment_type,
                  }),
                }).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status === 200) {
                    this.setState((prevState) => ({
                      items: prevState.items.map((produto) =>
                        produto.token === token
                          ? { ...produto, payment_type, payment_a: new Date() }
                          : produto
                      ),
                      boletop: {},
                    }));
                    toast.success("Pagamento efetuado com sucesso!");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.comprovante).length > 0}
          title="Informações de Devolução"
          onAfterClose={(result) => {
            this.setState({ comprovante: {} });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();

              var form = e.target;
              const formData = new FormData();

              // Adiciona os dados do formulário ao FormData
              console.log(this.state.selectedFile.length);
              if (this.state.comprovante.state == undefined) {
                toast.warn("Por favor, selecione o estado da devolução!");
                return;
              }

              if (
                this.state.comprovante.state == "2" &&
                this.state.selectedFile.length < 1
              ) {
                toast.warn("Por favor, Adicione as imagens da devolução!");
                return;
              }

              formData.append("client", this.state.comprovante.client_id);
              formData.append("obs_end", this.state.comprovante.desc);
              formData.append("state", this.state.comprovante.state);

              if (this.state.comprovante.state == "2") {
                this.state.selectedFile.forEach((file, index) => {
                  formData.append(`images`, file); // O backend deve suportar array de arquivos
                });
                var resp = fetch(
                  `${apiUrl}/finalizar-locacao/${this.state.comprovante.token}/files`,
                  {
                    method: "POST",
                    headers: {
                      authorization: localStorage.getItem("token"),
                    },
                    body: formData,
                  }
                );
              } else if (this.state.comprovante.state == "1") {
                var resp = fetch(
                  `${apiUrl}/finalizar-locacao/${this.state.comprovante.token}`,
                  {
                    method: "POST",
                    headers: {
                      authorization: localStorage.getItem("token"),
                    },
                    body: formData,
                  }
                );
              }

              resp
                .then((response) => {
                  if (response.status == 200) {
                    form[3].classList.remove("load");
                    this.setState((prevState) => ({
                      items: prevState.items.filter(
                        (item) => item.token !== this.state.comprovante.token
                      ),
                      comprovante: {},
                    }));

                    toast.success("Locação finalizada com sucesso!");
                  }
                })
                .catch((error) => {
                  form[3].classList.remove("load");
                  toast.error("Erro ao finalizar locação!");
                });
            }}
          >
            <div className="content">
              <div class="mb-3">
                <label for="nome-cliente" class="form-label">
                  Nome do Cliente
                </label>
                <input
                  type="text"
                  id="nome-cliente"
                  class="form-control"
                  value={this.state.comprovante.client_name}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label class="form-label">Estado do Equipamento</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    var v = e.target.value;
                    if (v == "") {
                      return;
                    }
                    if (this.state.comprovante.state == "1") {
                      this.setState({ selectedFile: [] });
                    }

                    this.setState((prevState) => ({
                      comprovante: {
                        ...prevState.comprovante,
                        ["state"]: e.target.value, // Atualiza apenas o campo especificado
                      },
                    }));
                  }}
                  value={this.state.comprovante.state || ""}
                >
                  <option value="">Selecione:</option>
                  <option value="1">Em Ordem</option>
                  <option value="2">Avariado</option>
                </select>
              </div>
              {this.state.comprovante.state == "2" && (
                <div className="mb-3">
                  <labe className="">Fotos do equipamento</labe>
                  <input
                    type="file"
                    multiple
                    accept="image/*" // Isso já ajuda a restringir para imagens no navegador
                    onChange={this.handleFileChange}
                    class="form-control mt-3"
                  />
                </div>
              )}

              <div>
                <textarea
                  class="form-control"
                  placeholder="Observação"
                  value={this.state.comprovante.desc || ""}
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      comprovante: {
                        ...prevState.comprovante,
                        ["desc"]: e.target.value, // Atualiza apenas o campo especificado
                      },
                    }));
                  }}
                  style={{ height: "100px" }}
                />
              </div>
            </div>
            <div className="modal-fotter">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faSave} /> Finalizar Locação
              </button>
            </div>
          </form>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.delmaster).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({ delmaster: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Se vocé confirmar a exclusão, não sera possivel recuperar os dados
            posteriormente.
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");

                var id = this.state.delmaster.id;

                fetch(`${apiUrl}/delete-lease`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({ id }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      toast.success("Locação excluida com sucesso!");
                      this.setState((prevState) => ({
                        items: prevState.items.filter((item) => item.id !== id),
                        delmaster: {},
                      }));
                    }
                  })
                  .catch((error) => {
                    toast.error("Erro ao enviar dados:", error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default CurrentLease;

import React from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Header from "./pages/Dashboard/Header";
import P404 from "./pages/P404";
import CadastroClientes from "./pages/CadastroClientes";
import CadastroMaquina from "./pages/CadastroMaquina";
import Budget from "./pages/Budget";
import NewBudget from "./pages/Budget/new";
import ViewBudget from "./pages/Budget/ViewBudget";
import EditBudget from "./pages/Budget/EditBudget";
import Login from "./pages/Login";
import Lease from "./pages/Leaser";
import ViewLeaser from "./pages/Leaser/ViewLeaser";
import LeaseHistoy from "./pages/Leaser/LeaseHistoy";
import Services from "./pages/Services";
import ClientView from "./pages/CadastroClientes/ClientView";
import EditClient from "./pages/CadastroClientes/EditClient";
import Stores from "./pages/Stores";
import SelectStore from "./pages/Stores/SelectStore";
import StoreSellers from "./pages/Stores/SelectStore/StoreSellers";
import Locations from "./pages/Stores/SelectStore/Locations";
import NewLocation from "./pages/Stores/SelectStore/Locations/NewLocation";
import EditLocation from "./pages/Stores/SelectStore/Locations/EditLocation";
import LeaserContract from "./pages/Leaser/LeaserContract";
import LeaserContractPrint from "./pages/Leaser/LeaserContractPrint";
import CurrentLease from "./pages/CurrentLease";
import ReNewLeaser from "./pages/ReNewLeaser";
import SalesHistoy from "./pages/SalesHistoy";
import BudgetSales from "./pages/BudgetSales";
import LocationDetails from "./pages/LocationDetails";
import SalesView from "./pages/SalesHistoy/SalesView";
import SalesBudgetView from "./pages/BudgetSales/SalesBudgetView";
import Contracts from "./pages/Contracts";
import Finances from "./pages/Finances";
import LeaserReceipt from "./pages/Leaser/LeaserReceipt";
import SaleReceipt from "./pages/SalesHistoy/SaleReceipt";

function App() {
  const navigate = useNavigate();

  // Verifica se o usuário está autenticado
  const isAuthenticated = !!localStorage.getItem("token");

  return (
    <>
      {isAuthenticated ? (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<CurrentLease navigate={navigate} />} />
            <Route
              path="/lease/view/:token"
              element={<ViewLeaser navigate={navigate} />}
            />

            <Route
              path="/location/details/:token"
              element={<LocationDetails navigate={navigate} />}
            />

            <Route path="/painel-adm" element={<Lease navigate={navigate} />} />

            <Route
              path="/lease-history"
              element={<LeaseHistoy navigate={navigate} />}
            />
            <Route
              path="/sales-history"
              element={<SalesHistoy navigate={navigate} />}
            />

            <Route
              path="/sales-history/view/:token"
              element={<SalesView navigate={navigate} />}
            />

            <Route
              path="/budget/sales/view/:token"
              element={<SalesBudgetView navigate={navigate} />}
            />

            <Route
              path="/lease/contract/:token"
              element={<LeaserContract navigate={navigate} />}
            />
            <Route
              path="/lease/contract/:token/print"
              element={<LeaserContractPrint navigate={navigate} />}
            />

            <Route
              path="/lease/receipt/:token"
              element={<LeaserReceipt navigate={navigate} />}
            />

            <Route
              path="/sales-history/receipt/:token"
              element={<SaleReceipt navigate={navigate} />}
            />

            <Route
              path="/lease/renew-location/:token"
              element={<ReNewLeaser navigate={navigate} />}
            />
            <Route path="/cadastro-clientes" element={<CadastroClientes />} />

            <Route
              path="/contracts"
              element={<Contracts navigate={navigate} />}
            />

            <Route
              path="/cadastro-clientes/view/:token"
              element={<ClientView navigate={navigate} />}
            />
            <Route
              path="/cadastro-clientes/edit/:token"
              element={<EditClient navigate={navigate} />}
            />

            <Route path="/equipamentos" element={<CadastroMaquina />} />

            <Route
              path="/financeiro"
              element={<Finances navigate={navigate} />}
            />

            <Route path="/budget" element={<Budget />} />
            <Route path="/budget/sales" element={<BudgetSales />} />
            <Route
              path="/budget/new"
              element={<NewBudget navigate={navigate} />}
            />
            <Route
              path="/budget/view/:token"
              element={<ViewBudget navigate={navigate} />}
            />
            <Route
              path="/budget/edit/:token"
              element={<EditBudget navigate={navigate} />}
            />
            <Route path="/services" element={<Services />} />
            <Route path="/stores" element={<Stores />} />
            <Route path="/stores/:token" element={<SelectStore />} />
            <Route path="/stores/:token/sellers" element={<StoreSellers />} />
            <Route
              path="/stores/:token/locations"
              element={<Locations navigate={navigate} />}
            />
            <Route
              path="/stores/:token/locations/add"
              element={<NewLocation navigate={navigate} />}
            />
            <Route
              path="/stores/:token/locations/:id/edit"
              element={<EditLocation navigate={navigate} />}
            />
            <Route path="*" element={<P404 />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      <ToastContainer />
    </>
  );
}

export default App;
